import React, { useEffect, useState } from "react"
import * as styles from "./styles.module.scss"

const Spacer = props => {

    const [responsiveHeight, setResponsiveHeight] = useState({});

    const calcStyles = (e) => {
        const windowWidth = document.documentElement.clientWidth;
        
        let height = props.style.height;

        if (windowWidth <= 1100) {
            height = props.style.height;
        }

        if (windowWidth <= 992) {
            if (props.style.heightTablet !== '') {
                height = props.style.heightTablet;
            }
        }

        if (windowWidth <= 768) {
            if (props.style.heightMobile !== '') {
                height = props.style.heightMobile;
            }
        }

        setResponsiveHeight(height);

    }

    useEffect(() => {

        calcStyles();
        window.addEventListener('resize', calcStyles);

        window.addEventListener('resize', calcStyles);
    }, [])

    return (
        <div style={{ height: responsiveHeight }} className={styles.container}></div>
    )


}


export default Spacer;