import React, { useEffect, useRef, useState } from "react";
import * as styles from "./styles.module.scss";
import Step from "./step";

const ScrollSection = (props) => {

  return (
    <div className={styles.wrapper}>
      {props.children.map((element, index) => (
        <Step key={index} alternate={index % 2 === 0} {...element} />
      ))}
    </div>
  );
};

export default ScrollSection;
