import React from "react";
import * as styles from "./styles.module.scss";
import parse from "html-react-parser";
import Person from "../Person";
import Quotes from "../../../images/gray_commas.png";

const Statement = (props) => {
  let imageOnRight = props.style.alignment === "image_right";
  let hasIcon = props.icon_toggle || false;

  return (
    <div
      className={`${styles.wrapper} container fullwidth ${
        imageOnRight && "reverseRow"
      }`}
    >
      <div className={`${styles.imageCol} col6`}>
        <div className={styles.imageSide}>
          <img className={imageOnRight ? styles.rightPart : styles.leftPart} src={props.image} alt={props.alt_text} />
          {hasIcon &&
          <div aria-hidden="true" className={styles.logoWrapper}>
              <img
                className={styles.logo}
                src={props.icon_over}
              />
            </div>
            }
        </div>
      </div>
      <div className={`${styles.textSide} col6`}>
        <div>
          <h3>{props.headline}</h3>
          <p className={styles.content}>
            <img aria-hidden="true" className={styles.grayQuotes} src={Quotes} alt="" />
            {parse(props.text)}
            </p>
            {props.person_toggle && <Person name={props.person_name} title={props.person_title} hasImage={props.image_toggle} photo={props.person_image} />}
        </div>
      </div>
    </div>
  );
};

export default Statement;
