import React from "react";
import * as styles from "./styles.module.scss";
import * as lps from "../../../scss/landingpage.module.scss";
import Scrolldowns from "./scrolldowns";
import Pokal from '../../../images/vierSaeulen.png';
import parse from "html-react-parser";

const LPHero = (props) => {
  return (
    <div className={`container row fullwidth ${styles.wrapper}`}>
      <div className="col12">
        <div>
          <div className="container row">
            <div className="col2"></div>
            <div className="col8">
              <div style={{ textAlign: "center" }}>
                <span className={styles.preheadline}>
                  {props.first_headline}
                </span>
                <h1 className={styles.headline}>
                  {parse(props.second_headline)}
                </h1>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                    marginBottom: 35,
                  }}
                >
                  <a className={lps.button} href="mailto:office@go4digital.cc?subject=Kostenlose E-Mail Analyse&body=Sehr geehrter Herr Sonnleitner,%0D%0A
hiermit bitte ich Sie, die für mich kostenlose E-Mail-Security-Analyse jener E-Mail-Domain, mit der ich diese E-Mail versende, durchzuführen.%0D%0A
Mit freundlichen Grüßen,%0D%0A%0D%0A%0D%0A
Achtung: Um eine die Analyse Ihrer betrieblichen E-Mail-Sicherheit zu ermöglichen, muss diese E-Mail über ihre berufliche E-Mail-Adresse an uns gesendet werden.">
                    {props.button_text}
                  </a>
                </div>
              </div>
            </div>
            <div className="col2"></div>
          </div>
          <div className="container row">
            <div className="col12">
              <div>
                <h2 className={styles.ablauf_headline}>
                  Ablauf der E-Mail Analyse
                </h2>
                <Scrolldowns
                  boxes={[
                    [props.scroll1_icon, props.scroll1_text, props.scroll1_id],
                    [props.scroll2_icon, props.scroll2_text, props.scroll2_id],
                    [props.scroll3_icon, props.scroll3_text, props.scroll3_id],
                  ]}
                />
                <div className={styles.logos}>
                  <img src={props.logos} alt="" />
                  <span>{props.logos_text}</span>
                </div>
              </div>
            </div>
          </div>
          <div className={`container row ${styles.fourPillars}`}>
            <div className="col12">
              <div>
                <h2>
                  Wir analysieren Ihre E‑Mail‑Security anhand der folgenden 4
                  Säulen:
                </h2>
                <img src={Pokal} alt="INBOUND PROTECTION, OUTBOUND SECURITY, ALWAYS-ON, CONFIGS" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LPHero;
