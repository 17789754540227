import React from "react";
import * as styles from "./styles.module.scss";
import Partner from "./Partner";

const PartnerDisplay = (props) => {

  return (
    <div className={styles.wrapper}>
      {props.children.map((element) => {
        return <Partner {...element} />
      })}
    </div>
  );
};

export default PartnerDisplay;
