import React, { useState } from "react";
import parse from "html-react-parser";
import * as styles from "./styles.module.scss";
import Caret from "../../../images/Caret_Black.png";

const Question = (props) => {
  const [open, setOpen] = useState(props.startOpen);

  const handleClick = () => setOpen((a) => !a);

  return (
    <div className={`${styles.item} ${open ? styles.open : ""}`}>
      <div onClick={handleClick} className={styles.trigger}>
        <h3>{props.headline}</h3>
        <img src={Caret} alt="" />
      </div>
      <div className={styles.answer}>{parse(props.text)}</div>
    </div>
  );
};

export default Question;
