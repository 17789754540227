import React from "react";
import * as styles from "./styles.module.scss";
import parse from "html-react-parser";
import Button from "../Button";

const TextImageCard = (props) => {
  let imageOnRight = props.style.alignment === "image_right";
  let hasMobileIcon = props.hasmobileicon;

  return (
    <div
      className={`${styles.wrapper} container fullwidth ${
        imageOnRight && "reverseRow"
      }`}
    >
      <div className={`${styles.imageCol} col6`}>
        <div className={styles.imageSide}>
          <img
            className={`${imageOnRight ? styles.rightPart : styles.leftPart}`}
            src={props.image}
            alt={props.alt}
            aria-hidden="true"
          />
          {hasMobileIcon && (
            <div className={styles.mobileIconWrapper}>
              <img
                className={styles.mobileIcon}
                src={props.mobile_icon}
                alt=""
                aria-hidden="true"
              />
            </div>
          )}
        </div>
      </div>
      <div className={`${styles.textSide} col6`}>
        <div>
          <h2 className="style-h3">{parse(props.headline)}</h2>
          <p className={styles.content}>{parse(props.text)}</p>
          {props.hasButton && (<div className={styles.buttonWrapper}>
            <Button
              aria_label={props.aria_label}
              type="tertiary"
              text={props.linkText}
              destination={props.link}
            />
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default TextImageCard;
