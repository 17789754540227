import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import parse from "html-react-parser";

const Step = (props) => {
  if (props.alternate) {
    return (
      <div className={`container row ${styles.step} ${styles.alternate}`}>
        <div className="col1" />
        <div className="col4">
          <div className={styles.listWrapper }>{parse(props.liste)}</div>
        </div>
        <div className="col2" />
        <div className="col5">
          <div>
            <span>{props.subheadline}</span>
            <h3>{props.headline}</h3>
            <p>{props.text}</p>
            <img src={props.bild} alt="" />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`container row ${styles.step}`}>
        <div className="col1" />
        <div className="col5">
          <div>
            <span>{props.subheadline}</span>
            <h3>{props.headline}</h3>
            <p>{props.text}</p>
            <img src={props.bild} alt="" />
          </div>
        </div>
        <div className="col2" />
        <div className="col4">
          <div className={styles.listWrapper}>{parse(props.liste)}</div>
        </div>
      </div>
    );
  }
};

export default Step;
