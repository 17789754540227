import React from 'react';
import * as styles from "./styles.module.scss"
const Person = ({name, title, hasImage, photo, padding}) => {

    return (
        <div className={styles.person} style={{paddingTop: (padding) ? padding : "",paddingBottom: (padding) ? padding : ""}}>
            {hasImage && <img alt="Bild der Person" src={photo}/>}
            <div>
                <div className={styles.name}>{name}</div>
                <div className={styles.title}>{title}</div>
            </div>
        </div>
    );
};

export default Person;
