const elementInViewport = (el) => {

    if (el !== null) {

        var top = el.offsetTop;
        var left = el.offsetLeft;
        var width = el.offsetWidth;
        var height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        return (
            top >= window.pageYOffset &&
            left >= window.pageXOffset &&
            (top + height) <= (window.pageYOffset + document.documentElement.clientHeight) &&
            (left + width) <= (window.pageXOffset + window.innerWidth)
        );

    }
    
}

export default elementInViewport;