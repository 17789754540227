import React from "react";
import parse from "html-react-parser";
import * as styles from "./styles.module.scss";
import Caret from '../../../images/Caret_Black.png';
import Question from "./question";

const LPFAQ = (props) => {
  console.log(props);

  return (
    <div className={styles.wrapper}>
      <span className={styles.subheadline}>{props.first_headline}</span>
      <h2 className={styles.headline}>{props.second_headline}</h2>
      {props.text}
      <div className={styles.items}>
        {props.children.map((e, i) => <Question startOpen={i === 1} {...e} />)}
      </div>
    </div>
  );
};

export default LPFAQ;
