import React, { useState } from "react";
import * as styles from "./styles.module.scss";
import Button from "../Button";
import { useForm } from "react-hook-form";
import axios from "axios";
import { API_BASE } from "../../../spectory-config";
import Loader from "react-loader-spinner";
import Check from "../../../images/white_check.png";

const ContactForm = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const onSubmit = (data) => {
    if (props.sendData === undefined) {
      const call = () => {
        setIsLoading(true);
        axios
          .post(API_BASE + "/contact", data)
          .then((res) => {
            setIsLoading(false);
            setIsSent(true);
          })
          .catch((err) => {});
      };
      call();
    } else {
      props.sendData(data);
    }
  };

  return (
    <div
      className={
        props.style === "compact" ? styles.compactWrapper : styles.wrapper
      }
    >
      <div className={`${styles.loadingOverlay} ${isLoading && styles.active}`}>
        <Loader type="TailSpin" color="#fff" height={100} width={100} />
        <p>Ladevorgang läuft</p>
      </div>
      {isSent ? 
      <div className={styles.sentWrapper}>
      <Button image={Check} type="primary" text="Daten erfolgreich abgeschickt" />
        </div>:
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <label htmlFor="name">Name *</label>
        <input
          className={errors.name && styles.invalid}
          {...register("name", { required: true, minLength: 3 })}
          placeholder="Vor- und Nachname"
        />
        <label htmlFor="company">Unternehmen</label>
        <input
          {...register("company")}
          id="company"
          name="company"
          placeholder="Ihre Firma"
        />
        <label htmlFor="email">E-Mail *</label>
        <input
          className={errors.email && styles.invalid}
          {...register("email", {
            required: true,
            minLength: 3,
            pattern: /^\S+@\S+\.\S+$/,
          })}
          placeholder="ihre@email.com"
        />
        <label htmlFor="phone">Telefon</label>
        <input {...register("phone")} placeholder="+43 123 4567890" />
        <p style={{fontSize: '0.75rem'}}>* Pflichtfeld</p>
        <p style={{color: 'red', display: (errors.name || errors.email) ? 'block' : 'none', fontSize: '0.8rem'}}>Bitte alle mit Stern markierten Pflichtfelder (Name & E-Mail) ausfüllen.</p>
        <div
          className={`${styles.buttonWrapper} ${
            props.disableButton && styles.notActive
          }`}
        >
          <Button
            type="tertiary"
            aria_label="Bestätigen zum Absenden des Formulares"
            formButton={true}
            text={
              props.customText === undefined
                ? "Daten abschicken"
                : props.customText
            }
          />
        </div>
      </form> }
    </div>
  );
};

export default ContactForm;
