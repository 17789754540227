import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";
import Caret from "../../../images/Caret_Blue.png";

const Scrolldowns = ({ boxes }) => {
  let [step, setStep] = useState(0);

  const [sw, setSW] = useState(1920);

  useEffect(() => {
    setSW(window.innerWidth);
  }, [sw]);



  const tryPrev = () => {
    setStep((i) => Math.max(0, i - 1));
  };

  const tryNext = () => {
    setStep((i) => Math.min(2, i + 1));
  };

  return (
    <>
      <div className={`${styles.scrolldowns} ${step === 0 ? '' : step === 1 ? styles.two : styles.three}`}>
        {boxes.map((item, index) => {
          return (
            <a
              className={`${styles.scrolldown_box} ${
                index == 0 ? styles.s0 : index === 1 ? styles.s1 : styles.s2
              }`}
              href={`#${sw > 768 ? 'ablauf' : item[2]}`}
            >
              <img src={item[0]} alt="" />
              <h2>{item[1]}</h2>
            </a>
          );
        })}
        <div className={styles.placeholder}></div>
      </div>
      <div className={styles.controlls}>
        <button onClick={tryPrev} className={styles.arrowButton}>
          <img src={Caret} />
        </button>
        <div className={styles.progress}>
          <div
            className={`${styles.bar} ${
              step === 0 ? "" : step === 1 ? styles.two : styles.three
            }`}
          />
          <span>{step + 1} von 3</span>
        </div>
        <button onClick={tryNext} className={styles.arrowButton}>
          <img src={Caret} />
        </button>
      </div>
    </>
  );
};

export default Scrolldowns;
