import React, {useEffect, useRef} from "react"
import * as styles from "./styles.module.scss"
import elementInViewport from "../../../func/elementInViewPort";

const Video = ({url, style}) => {
    const ref = useRef()
    let played = false;
    useEffect(() => {
        if (ref.current) {
            document.addEventListener("scroll", () => {
                if (!played) {
                    if (elementInViewport(ref.current)) {
                        ref.current.play();
                        played = true;
                    }
                }
            })
        }
        ref.current.onended = () => {
            played = true;
            // replayRef.current.classList.add(styles.active);
        }

        return () => {
            document.removeEventListener("scroll", () =>{})
        }
    }, [])
    let sProps = {maxWidth: style.width, display: "inline-block"}
    if (style.position === "center") {
        sProps.marginLeft = "auto";
        sProps.marginRight = "auto";
    } else if (style.position === "right") {
        sProps.float = "right";
    }
    return <div className={styles.video}>
        <video muted={true} style={sProps} src={url} ref={ref}  type='video/mp4' playsInline={true} disablePictureInPicture={true}></video>
        {/*<div ref={replayRef} className={styles.replayButton}>*/}
        {/*    <IoReloadCircleOutline onClick={() => {*/}
        {/*        ref.current.play();*/}
        {/*        played = false;*/}
        {/*        replayRef.current.classList.remove(styles.active)*/}
        {/*    }}/>*/}
        {/*</div>*/}
    </div>
}

export default Video
