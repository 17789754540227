import React, { useState, useEffect } from "react";
import parse from 'html-react-parser';
import * as styles from "./styles.module.scss";

const LPAblauf = (props) => {
  console.log(props);

  const [sw, setSW] = useState(1920);

  useEffect(() => {
    setSW(window.innerWidth);
  }, [sw]);

  return (
    <div className={styles.wrapper}>
      <span id={sw > 768 ? 'ablauf' : ''} className={styles.subheadline}>{props.first_headline}</span>
      <h2 className={styles.headline}>{props.second_headline}</h2>
      {parse(props.text)}
      <div className={styles.items}>
        {props.children.map(e => {
            console.log(e);
            return (
                <div id={sw > 768 ? '' : e.anker} className={styles.item}>
                    <img src={e.bild} alt="" />
                    <h3>{e.headline}</h3>
                    {parse(e.text)}
                </div>
            )
        })}
      </div>
    </div>
  );
};

export default LPAblauf;
