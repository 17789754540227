import React from "react";
import * as styles from "./styles.module.scss";

const Partner = (props) => {
  return (
    <div className={styles.element}>
      <img alt="" src={props.image_url} />
      <h4>{props.name}</h4>
    </div>
  );
};

export default Partner;
