import React, { useEffect } from "react";
import * as styles from "./styles.module.scss";
import CheckIcon from "../../../images/CheckIcon.png";

const Vorteil = (props) => {
  let usedIcon = props.icon !== undefined ? props.icon : CheckIcon;

  return (
    <div className={styles.itemWrapper}>
      <img src={usedIcon} alt="" />
      {props.upperText.startsWith('+') ?
        <p><a aria-label="Telefonnummer für den Standort, öffnet im Telefondienst" href={`tel:${props.upperText.replace(' ', '')}`}>{props.upperText}</a></p> :
        props.upperText.startsWith('office') ?
          <p><a aria-label="E-Mail-Adresse für den Standort, öffnet im Mail-Programm" href={`mailto:${props.upperText}`}>{props.upperText}</a></p> :
          <p>{props.upperText}<br />{props.lowerText}</p>
      }
    </div>
  );
};

export default Vorteil;
