import React from "react";
import * as styles from "./styles.module.scss";

const IconBar = (props) => {
  let addStyles = {
    marginTop: props.style.marginTop,
    marginBottom: props.style.marginBottom
};

  return (
    <div style={addStyles} className={styles.wrapper}>
      <div className={styles.iconWrapper}>
        <img aria-hidden="true" src={props.icon1} alt="Icon" />
        <p>{props.text1}</p>
      </div>

      <div className={styles.iconWrapper}>
        <img aria-hidden="true" src={props.icon2} alt="Icon" />
        <p>{props.text2}</p>
      </div>

      <div className={styles.iconWrapper}>
        <img aria-hidden="true" src={props.icon3} alt="Icon" />
        <p>{props.text3}</p>
      </div>
    </div>
  );
};

export default IconBar;
