import React, { useEffect, useState } from "react";
import AnonymousIcon from "../../../images/anon_booking.png";
import DataBookingIcon from "../../../images/kontaktdaten.png";
import Button from "../Button";
import CalIconBlack from "../../../images/svg/calendar_black.svg";
import ClockIcon from "../../../images/svg/clock.svg";
import ChainIcon from "../../../images/svg/chain.svg";
import * as styles from "./styles.module.scss";
import IconOne from "../../../images/svg/anon-icon.svg";
import IconTwo from "../../../images/svg/data-icon.svg";
import ArrowLeft from "../../../images/ArrowLeft.png";
import ArrowRight from "../../../images/ArrowRight.png";
import EmailIcon from "../../../images/svg/email-sent.svg";
import IconCheck from "../../../images/CheckIcon.png";
import DatePicker, { registerLocale } from "react-datepicker";
import { de } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ContactForm from "../ContactForm";
import axios from "axios";
import { API_BASE } from "../../../spectory-config";
import ICalendarLink from "react-icalendar-link";
import Loader from "react-loader-spinner";
import uuid from "react-uuid";
import smoothscroll from 'smoothscroll-polyfill';

const AppointmentBooking = () => {

  const uniqueID = uuid();

  const getDateIndex = (date) => {
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let monthPart = `${month < 10 ? "0" : ""}${month}`;
    return `${year}-${monthPart}`;
  };

  const parseIndex = (index) => {
    return new Date(`${index}-01`);
  };

  const [popToggle, setPopToggle] = useState(false);
  const [appointmentType, setAppointmentType] = useState(0);
  const [stepNr, setStepNr] = useState(2);
  const [timeNr, setTimeNr] = useState(0);
  const [chosenDate, setChosenDate] = useState(0);
  const [timesArray, setTimesArray] = useState([]);
  const [zoomLink, setZoomLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [allTermine, setAllTermine] = useState([]);
  const [dateIndex, setDateIndex] = useState(getDateIndex(new Date()));

  // Setup für Kalendar
  const [startDate, setStartDate] = useState(new Date());
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const onChange = (date) => {
    let index = getDateIndex(date);
    setTimeNr(0);
    setStartDate(date);
    setChosenDate(1);
    setTimesArray(allTermine[index][formatDate(date)]);
    setDateIndex(getDateIndex(date));
  };

  useEffect(() => {
    allTermine[dateIndex] === undefined && getTermine(dateIndex);
    let temp = parseIndex(dateIndex);
    temp.setMonth(temp.getMonth() - 1);
    allTermine[getDateIndex(temp)] === undefined &&
      getTermine(getDateIndex(temp));
    temp.setMonth(temp.getMonth() + 2);
    allTermine[getDateIndex(temp)] === undefined &&
      getTermine(getDateIndex(temp));
  }, [dateIndex]);

  const filterDays = (date) => {
    let index = getDateIndex(date);
    return allTermine[index] === undefined
      ? false
      : allTermine[index].hasOwnProperty(formatDate(date));
  };

  registerLocale("de", de);
  // Ende

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  const getTermine = (index) => {
    let split = index.split("-");
    setIsLoading(true);
    allTermine[index] === undefined &&
      axios
        .post(API_BASE + "/appointments", {
          year: split[0],
          month: split[1],
        })
        .then((res) => {
          setAllTermine((prev) => ({ ...prev, [index]: res.data.data }));
          setIsLoading(false);
        })
        .catch((err) => {}) 
        .finally(() => {
        } );
  };

  useEffect(() => {
    smoothscroll.polyfill();
    getTermine(dateIndex);
    let temp = parseIndex(dateIndex);
    temp.setMonth(temp.getMonth() - 1);
    getTermine(getDateIndex(temp));
    temp.setMonth(temp.getMonth() + 2);
    getTermine(getDateIndex(temp));
  }, []);

  const handleClick = () => {
    setPopToggle((prevState) => !prevState);
    if (!popToggle) {
      let yOffset = (window.innerHeight / 6) * -1;

      let element = document.getElementById(uniqueID);
      let y =
        element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }else{
      setAppointmentType(0)
    }
  };
  const handleSendForm = (data) => {
    let info = { user: data, id: timeNr };
    const call = () => {
      setIsLoading(true);
      axios
        .post(API_BASE + "/appointment/book", info)
        .then((res) => {
          setZoomLink(res.data.data);
          setStepNr(4);
          setIsLoading(false);
        })
        .catch((err) => {
        });
    };
    call();
  };

  const handleSelectDate = () => {
    if (timeNr !== 0) {
      if (appointmentType > 0) {
        setStepNr(3);
      } else {
        const call = () => {
          setIsLoading(true);
          let info = { id: timeNr };
          axios
            .post(API_BASE + "/appointment/book", info)
            .then((res) => {
              setZoomLink(res.data.data);
              setStepNr(4);
              setIsLoading(false);
            })
            .catch((err) => {
            });
        };
        call();
      }
    }
  };

  const getAppointmentEnd = () => {
    let temp = new Date(startDate.getTime() + 60 * 60000);
    return temp;
  };

  const getSelectedDateString = () => {
    return (
      (startDate.getDate() > 9
        ? startDate.getDate()
        : "0" + startDate.getDate()) +
      "/" +
      (startDate.getMonth() > 8
        ? startDate.getMonth() + 1
        : "0" + (startDate.getMonth() + 1)) +
      "/" +
      startDate.getFullYear()
    );
  };

  const getSelectedHours = () => {
    let hourString = "0:00";
    timesArray.map((cell) => {
      if (cell.id === timeNr) {
        hourString = cell.start;
      }
    });
    hourString = hourString.split(" ")[0];
    hourString = hourString.split(":");
    return [hourString[0], hourString[1]];
  };

  const getSelectedTimeString = () => {
    let arr = getSelectedHours();
    return `${arr[0]}:${arr[1] === 0 ? "00" : arr[1]} - ${parseInt(arr[0]) + 1}:${arr[1]} Uhr`;
  };

  const setTime = (buttonNr) => {
    setTimeNr(buttonNr);
  };

  const hideAll = () => {
    setPopToggle(false);
    setStepNr(2);
    setTimeNr(0);
    setChosenDate(0);
    setAppointmentType(0);
  }

  useEffect(() => {
    if (timeNr !== 0) {
      let newDate = startDate;
      let hours = getSelectedHours();
      newDate.setHours(hours[0], hours[1], 0);
      setStartDate(newDate);
    }
  }, [timeNr]);
 
   /* Soll Terminplaner schließen wenn woanders gedrückt wird
   const outsideClickListener = event => {
    let element = document.getElementById(uniqueID);
    console.log('got it');

    if (!element?.contains(event.target)) {
      setAppointmentType(0);
      console.log('got it');
    }
}

document.addEventListener('click', outsideClickListener); */

  return (<>
  <div onClick={hideAll} className={`${styles.wholeOverlay} ${popToggle && styles.activated}`} />
    <div
      id={uniqueID}
      className={`${styles.wrapper} ${popToggle && styles.visible}`}
    >
      <div style={{marginBottom: appointmentType == -1 ? 0 : 8, display: appointmentType == 1 ? "none" : "block"}}>
      <Button
        type="primary"
        onClick={() => {
          setAppointmentType(-1);
          handleClick();
        }}
        image={AnonymousIcon}
        aria_label="Hier geht es zur Terminvereinbarung via Videocall ohne Eingabe Ihrer Kontaktdaten"
        text="Anonymen Termin auswählen"
      />
      </div>
      <div style={{display: appointmentType == -1 ? "none" : "block"}}>
      <Button
        type="secondary"
        onClick={() => {
          setAppointmentType(1);
          handleClick();
        }}
        image={DataBookingIcon}
        aria_label="Hier geht es zur Terminvereinbarung via Videocall mit Eingabe Ihrer Kontaktdaten"
        text="Termin mit Kontaktdaten vereinbaren"
      />
      </div>
      <div className={`${styles.popWrapper} ${popToggle && styles.visible}`}>
        <div
          className={`${styles.loadingOverlay} ${isLoading && styles.active}`}
        >
          <Loader type="TailSpin" color="#fff" height={100} width={100} />
          <p>Ladevorgang läuft</p>
        </div>
        {stepNr === 2 && (
          <div className={styles.popContent}>
            <p
              className={styles.smallHeading}
              style={{ alignSelf: "flex-start" }}
            >
              Wählen Sie ein Datum:
            </p>
            <div className={styles.datePicker}>
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={0}
                filterDate={filterDays}
                minDate={new Date()}
                locale="de"
                inline
                adjustDateOnChange={false}
                renderCustomHeader={({
                  monthDate,
                  decreaseMonth,
                  increaseMonth,
                  prevMonthButtonDisabled,
                }) => (
                  <div className={styles.calHeader}>
                    <button
                      className={prevMonthButtonDisabled && styles.invisible}
                      aria-label="Termine vom letzten Monat ansehen"
                      disabled={prevMonthButtonDisabled}
                      onClick={() => {
                        decreaseMonth();
                        let temp = parseIndex(dateIndex);
                        temp.setMonth(temp.getMonth() - 1);
                        setDateIndex(getDateIndex(temp));
                        setStartDate();
                        setTimesArray([]);
                        setChosenDate(0);
                      }}
                    >
                      <img src={ArrowLeft} alt="" />
                    </button>
                    <p>
                      {monthDate.toLocaleString("de", {
                        month: "long",
                        year: "numeric",
                      })}
                    </p>
                    <button
                      aria-label="Termine vom nächsten Monat ansehen"
                      tabIndex={0}
                      onClick={() => {
                        increaseMonth();
                        let temp = parseIndex(dateIndex);
                        temp.setMonth(temp.getMonth() + 1);
                        setDateIndex(getDateIndex(temp));
                        setStartDate();
                        setTimesArray([]);
                        setChosenDate(0);
                      }}
                    >
                      <img src={ArrowRight} alt="" />
                    </button>
                  </div>
                )}
              />
            </div>
            <div className={styles.timeSelectWrapper}>
              <div className={chosenDate === 0 && styles.overlay}></div>
              <p
                className={styles.smallHeading}
                style={{
                  alignSelf: "flex-start",
                  visibility:
                    timesArray !== undefined && timesArray.length === 0
                      ? "hidden"
                      : "visible",
                }}
              >
                Wählen Sie eine Uhrzeit:
              </p>
              <div
                style={{
                  visibility:
                    timesArray !== undefined && timesArray.length === 0
                      ? "hidden"
                      : "visible",
                }}
                className={styles.timeSelect}
              >
                {timesArray !== undefined &&
                  timesArray.map((timeCell) => {
                    return (
                      <button
                        onClick={() => setTime(timeCell.id)}
                        className={`${styles.timeCell} ${
                          timeNr === timeCell.id && styles.selected
                        }`}
                      >
                        <p>{timeCell.start}</p>
                      </button>
                    );
                  })}
              </div>
            </div>
            <div
              className={`${styles.bottomButtonContainer} ${
                timeNr === 0 && styles.notActive
              }`}
            >
              <Button
                type="tertiary"
                onClick={handleSelectDate}
                disabled={timeNr === 0}
                text={
                  appointmentType > 0
                    ? "Nächster Schritt"
                    : "Erstgespräch vereinbaren"
                }
              />
            </div>
          </div>
        )}
        {stepNr === 3 && (
          <div className={styles.popContent}>
            <p
              style={{ alignSelf: "flex-start" }}
              className={styles.smallHeading}
            >
              Kontaktformular ausfüllen:
            </p>
            <ContactForm
              sendData={handleSendForm}
              style="compact"
              customText="Erstgespräch vereinbaren"
            />
          </div>
        )}
        {stepNr === 4 && (
          <div className={styles.popContent}>
            <div className={styles.successMessage}>
              <img src={IconCheck} alt="" />
              <p>Ihr Termin wurde erfolgreich vereinbart!</p>
            </div>
            <div className={styles.terminInfo}>
              <div>
                <CalIconBlack
                  className={styles.infoIcon}
                  alt="Termin"
                />
                <p>{getSelectedDateString()}</p>
              </div>
              <div>
                <ClockIcon
                  className={styles.infoIcon}
                  alt="Uhrzeit"
                />
                <p>{getSelectedTimeString()}</p>
              </div>
              <div>
                <button
                  onClick={() => navigator.clipboard.writeText(zoomLink)}
                  className={styles.whiteCircle}
                >
                  <ChainIcon alt="Link kopieren" />
                </button>
                <p>
                  Zoom-Meeting:
                  <br />
                  <a href={zoomLink} className={styles.zoomLink}>
                    {zoomLink}
                  </a>
                </p>
              </div>
            </div>
            {appointmentType === 1 && (
              <React.Fragment>
                <p style={{ textAlign: "center", marginBottom: 32 }}>
                  Es wurde eine Bestätigungs-Email an ihr Postfach versendet.
                  <br />
                  Bitte überprüfen Sie auch Ihren Spam-Ordner.
                </p>
                <EmailIcon
                  style={{ marginBottom: 55 }}
                  alt="E-Mail wurde versendet"
                />{" "}
              </React.Fragment>
            )}
            <ICalendarLink
              filename="go4digital-Termin.ics"
              event={{
                title: "go4digital Erstgespräch",
                description: `Ihr Erstgespräch bei go4digital! Zoom-link: ${zoomLink}`,
                startTime: startDate,
                endTime: getAppointmentEnd(),
                location: "Online",
              }}
            >
              <button className={styles.vormerken}>
                <p>Kalendereintrag herunterladen</p>
              </button>
            </ICalendarLink>
          </div>
        )}
      </div>
    </div>
    </>);
};

export default AppointmentBooking;
