import React, { useEffect } from "react";
import * as styles from "./styles.module.scss";
import smoothscroll from 'smoothscroll-polyfill';
import parse from "html-react-parser";


const IconNav = (props) => {

  useEffect(()=> {
    smoothscroll.polyfill();
  }, []);

  let hasAnker = props.anker !== "";
  const regex = /(<([^>]+)>)/gi;


  const handleClick = () => {
    hasAnker && 
    document.getElementById(props.anker).scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "center",
    });
  };

  return (
    <div id={props.id} className={styles.iconWrapper}>
      <a className={hasAnker && styles.hasAnker} onClick={handleClick}>
        <img src={props.icon} alt={`Zur Leistung ${props.headline.replace(regex, "")}`} />
        <h3 aria-hidden="true" className="style-h4">{parse(props.headline)}</h3>
      </a>
    </div>
  );
};

export default IconNav;
