import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./styles.scss";
import renderElements from "../index";
const Row = (props) => {
  const row = useRef(null);
  const [paddingTop, setPaddingTop] = useState(0);
  const [paddingBottom, setPaddingBottom] = useState(0);
  const [marginTop, setMarginTop] = useState(0);
  const [marginBottom, setMarginBottom] = useState(0);
  const [width, setWidth] = useState(-1);
  const render_columns = () => {
    let output = [];
    for (var i = 1; i <= parseInt(props.columns); i++) {
      output.push(
        <div key={i} className={"col" + props.column_sizes["col" + i]}>
          <div>
            {renderElements(props.children[i - 1].children, props.lang)}
          </div>
        </div>
      );
    }
    return output;
  };
  let id = {};
  let classes = props.classes;
  if (props.sidemenu_id !== null && props.sidemenu_id !== "") {
    id = props.sidemenu_id;
  } else {
    id = uuidv4();
  }
  const calcStyles = () => {
    const windowWidth = document.documentElement.clientWidth;
    let breakpoint = 0;
    let styles = {
      marginTop: props.style.marginTop,
      marginBottom: props.style.marginBottom,
      paddingTop: props.style.paddingTop,
      paddingBottom: props.style.paddingBottom,
    };
    if (windowWidth <= 992) {
      breakpoint = 992;
      if (props.style.marginTopTablet !== null) {
        styles.marginTop = props.style.marginTopTablet;
      }
      if (props.style.marginBottomTablet !== null) {
        styles.marginBottom = props.style.marginBottomTablet;
      }
      if (props.style.paddingTopTablet !== null) {
        styles.paddingTop = props.style.paddingTopTablet;
      }
      if (props.style.paddingBottomTablet !== null) {
        styles.paddingBottom = props.style.paddingBottomTablet;
      }
    }
    if (windowWidth <= 768) {
      breakpoint = 768;
      if (props.style.marginTopMobile !== null) {
        styles.marginTop = props.style.marginTopMobile;
      }
      if (props.style.marginBottomMobile !== null) {
        styles.marginBottom = props.style.marginBottomMobile;
      }
      if (props.style.paddingTopMobile !== null) {
        styles.paddingTop = props.style.paddingTopMobile;
      }
      if (props.style.paddingBottomMobile !== null) {
        styles.paddingBottom = props.style.paddingBottomMobile;
      }
    }
    if (width !== breakpoint) {
      setPaddingTop(styles.paddingTop);
      setPaddingBottom(styles.paddingBottom);
      setMarginTop(styles.marginTop);
      setMarginBottom(styles.marginBottom);
      setWidth(breakpoint);
    }
    // row.current.style = {...props.style, ...styles}
  };
  useEffect(() => {
    // ERROR HERE!!!
    calcStyles();
    window.addEventListener("resize", calcStyles);
    if (classes.includes("bg_right")) {
      calcWidth();
      window.addEventListener("resize", calcWidth);
    }
    return () => {
      window.removeEventListener("resize", calcStyles);
      if (classes.includes("bg_right")) {
        window.removeEventListener("resize", calcWidth);
      }
    };
  }, []);
  const calcWidth = () => {
    const windowRight = window.outerWidth;
    const containerLeft = row.current.getBoundingClientRect().x;
    const newWidth = windowRight - containerLeft;
    row.current.style.width = newWidth + "px";
  };
  return (
    <div
      id={id}
      ref={row}
      style={{
        ...props.style,
        paddingTop: paddingTop,
        paddingBottom: paddingBottom,
        marginTop: marginTop,
        marginBottom: marginBottom,
      }}
      className={"container row " + classes.join(" ")}
    >
      {render_columns()}
    </div>
  );
};
export default Row;
