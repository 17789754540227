// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--1022f";
export var visible = "styles-module--visible--37BGi";
export var popWrapper = "styles-module--popWrapper--2U9zi";
export var popContent = "styles-module--popContent--1osDK";
export var choice = "styles-module--choice--1xSP_";
export var successMessage = "styles-module--successMessage--1ezUz";
export var choiceButton = "styles-module--choiceButton--3DgLV";
export var selected = "styles-module--selected--12-_Q";
export var bottomButtonContainer = "styles-module--bottomButtonContainer--3zS_M";
export var notActive = "styles-module--notActive--2UCMY";
export var smallHeading = "styles-module--smallHeading--2mEI1";
export var timeSelect = "styles-module--timeSelect--2J5K_";
export var timeSelectWrapper = "styles-module--timeSelectWrapper--2dXg2";
export var overlay = "styles-module--overlay--1U5nO";
export var timeCell = "styles-module--timeCell--3V04w";
export var terminInfo = "styles-module--terminInfo--13Prl";
export var infoIcon = "styles-module--infoIcon--2Zry0";
export var whiteCircle = "styles-module--whiteCircle--11UWK";
export var zoomLink = "styles-module--zoomLink--3NTIW";
export var vormerken = "styles-module--vormerken--20Euq";
export var form = "styles-module--form--2ZPxn";
export var loadingOverlay = "styles-module--loadingOverlay--AwnFw";
export var active = "styles-module--active--3d0TZ";
export var calHeader = "styles-module--calHeader--4kaBS";
export var invisible = "styles-module--invisible--3QsAI";
export var wholeOverlay = "styles-module--wholeOverlay--3HPzm";
export var activated = "styles-module--activated--yQ6ip";