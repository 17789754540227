import React from "react";
import * as styles from "./styles.module.scss";
import IconLink from "./IconLink";
import parse from "html-react-parser";

const IconNav = (props) => {
  let onlySixElements = !props.has_seven;

  return (
    <div id="icons" className={styles.wrapper}>
      <div className={styles.hiddenAnker}></div>
      <div className={styles.headlineContainer}>
      <h2 className="style-h1">{parse(props.big_headline)}</h2>
      </div>
      <IconLink icon={props.icon1} headline={props.headline1} anker={props.anker1} />
      <IconLink icon={props.icon2} headline={props.headline2} anker={props.anker2} />
      <IconLink icon={props.icon3} headline={props.headline3} anker={props.anker3} />
      <div className={styles.hideOnMobile}></div>
      {onlySixElements && <div className={styles.hideOnMobile}></div>}
      {props.icon4 && <IconLink icon={props.icon4} headline={props.headline4} anker={props.anker4} />}
      {props.icon5 && <IconLink icon={props.icon5} headline={props.headline5} anker={props.anker5} />}
      {props.icon6 && <IconLink icon={props.icon6} headline={props.headline6} anker={props.anker6} />}
      {onlySixElements || <IconLink icon={props.icon7} headline={props.headline7} anker={props.anker7} />}
    </div>
  );
};

export default IconNav;
