import React from "react";
import * as styles from "./styles.module.scss";
import parse from "html-react-parser";

const PartnerCard = (props) => {
  let imageOnRight = props.style.alignment === "image_right";
  return (
    <div
      className={`${styles.wrapper} container fullwidth ${
        imageOnRight && "reverseRow"
      }`}
    >
      <div className={`${styles.imageCol} col6`}>
        <div className={styles.imageSide}>
          <img
            className={`${imageOnRight ? styles.rightPart : styles.leftPart}`}
            src={props.image}
            alt=""
          />
            <div aria-hidden="true" className={styles.logoWrapper}>
              <img
                className={styles.logo}
                src={props.logo}
                alt=""
              />
            </div>
        </div>
      </div>
      <div className={`${styles.textSide} col6`}>
        <div>
          <h2>{props.headline}</h2>
          <p className={styles.content}>{parse(props.text)}</p>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
