import React from "react";
import * as styles from "./styles.module.scss";
import Vorteil from "./vorteil";

const VorteileListe = (props) => {

  return (
    <div className={styles.wrapper}>
      {props.children.map((element) => {
        return <Vorteil {...element} />
      })}
    </div>
  );
};

export default VorteileListe;
