// extracted by mini-css-extract-plugin
export var wrapper = "styles-module--wrapper--3Koyd";
export var preheadline = "styles-module--preheadline--3xZ2o";
export var headline = "styles-module--headline--1lzxb";
export var ablauf_headline = "styles-module--ablauf_headline--21cSv";
export var scrolldowns = "styles-module--scrolldowns--pcdCr";
export var two = "styles-module--two--17DqC";
export var s0 = "styles-module--s0--2v-ej";
export var s1 = "styles-module--s1--3EUes";
export var s2 = "styles-module--s2--1AjRm";
export var three = "styles-module--three--1uDah";
export var scrolldown_box = "styles-module--scrolldown_box--TbgY3";
export var placeholder = "styles-module--placeholder--36S3o";
export var logos = "styles-module--logos--1d4GJ";
export var controlls = "styles-module--controlls--pzQgr";
export var progress = "styles-module--progress--1KOC0";
export var bar = "styles-module--bar--1t-Uy";
export var arrowButton = "styles-module--arrowButton--23AdD";
export var fourPillars = "styles-module--fourPillars--1hFtt";