import React, { useEffect, useRef } from "react";
import * as styles from "./headline.module.scss";
import parse from "html-react-parser";
import elementInViewport from "../../../func/elementInViewPort";
import animateCSS from "../../../func/animateCSS";

const Headline = (props) => {
	const ref = useRef();
	const showAsDiff = props.showAs && props.showAs !== 'default';

	let sProps = {};
	if (props.hasOwnProperty("style")) {
		sProps.marginBottom = props.style.margin_bottom;
		sProps.marginTop = props.style.margin_top;
		sProps.textAlign = props.style.stroke_alignment;
	}

	useEffect(() => {
		let animatedLocal = false;

		const check = () => {
			if (ref.current && props.animate) {
				if (elementInViewport(ref.current)) {
					if (!animatedLocal) {
						animateCSS(ref.current, "fadeInUp").then((message) => {
							ref.current.classList.add(styles.show);
							// setAnimated(true)
						});
						animatedLocal = true;
					}
				}
			}
		};

		check(ref);

		document.addEventListener(
			"scroll",
			() => {
				check(ref);
			},
			[],
		);
	});

	const renderHeadline = () => {
		switch (props.type) {
			case "h1":
				return (
					<h1
						className={
							`${showAsDiff ? props.showAs : ''} ${styles.headline} ` +
							(props.stroke
								? `stroke ` +
								  (props.right ? `${styles.right} ` : `${styles.left} `) +
								  (props.center ? styles.center : "") +
								  (props.edge ? `${styles.toEdge} ` : null)
								: null)
						}
						style={sProps}
					>
						{parse(props.headline || props.text)}
					</h1>
				);

			case "h2":
				return (
					<h2
						className={
							`${showAsDiff ? props.showAs : ''} ${styles.headline} ` +
							(props.stroke
								? `stroke ` +
								  (props.right ? `${styles.right} ` : `${styles.left} `) +
								  (props.center ? styles.center : "") +
								  (props.edge ? `${styles.toEdge} ` : null)
								: null)
						}
						style={sProps}
					>
						{parse(props.headline || props.text)}
					</h2>
				);

			case "h3":
				return (
					<h3
						className={
							`${showAsDiff ? props.showAs : ''} ${styles.headline} ` +
							(props.stroke
								? `${styles.stroke} ` +
								  (props.right ? `${styles.right} ` : `${styles.left} `) +
								  (props.center ? styles.center : "") +
								  (props.edge ? `${styles.toEdge} ` : null)
								: null)
						}
						style={sProps}
					>
						{parse(props.headline || props.text)}
					</h3>
				);
			default:
				return (<h2>{parse(props.headline)}</h2>);
		}
	};

	return (
		<div
			ref={ref}
			className={`${styles.headlineContainer} ${!props.animate && styles.show}`}
		>
			{props.subheadline ? (
				<div className={styles.subTitle}>{props.subheadline}</div>
			) : null}
			{renderHeadline()}
		</div>
	);
};

export default Headline;
