/**
 * Page elements like Text, Headline, Slider, etc.
 */
import React from "react";
import { v4 as uuidv4 } from "uuid";
import Button from "./Button";
import Image from "./Image";
import Person from "./Person";
import Row from "./Row";
import Text from "./Text";
import Video from "./Video";
import Spacer from "./Spacer";
import Headline from "./Headline";
import AppointmentBooking from "./AppointmentBooking";
import IconBar from "./IconBar";
import IconNav from "./IconNav";
import TextImageCard from "./TextImageCard";
import VorteileListe from "./VorteileListe";
import Counter from "./Counter";
import Statement from "./Statement";
import ContactForm from "./ContactForm";
import PartnerDisplay from "./PartnerDisplay";
import PartnerCard from "./PartnerCard";
import LPHero from "./LPHero";
import LPAblauf from "./LPAblauf";
import ScrollSection from "./ScrollSection";
import LPButton from "./LPButton";
import LPFAQ from "./LPFAQ";

const renderElements = (content_raw) => {
  let content = content_raw;

  if (typeof content_raw !== "object") {
    try {
      content = JSON.parse(content_raw);
    } catch {
      return "NO OBJECT FOUND";
    }
  }

  if (content === null) {
    return "NO OBJECT FOUND";
  }
  return content.map((element) => {
    if (element != null) {
      switch (element.id) {
        case "partner_card":
          return <PartnerCard key={uuidv4()} {...element} />;
        case "partner_display":
          return <PartnerDisplay key={uuidv4()} {...element} />;
        case "contact_form":
          return <ContactForm key={uuidv4()} {...element} />;
        case "statement":
          return <Statement key={uuidv4()} {...element} />;
        case "counter":
          return <Counter key={uuidv4()} {...element} />;
        case "vorteile_container":
          return <VorteileListe key={uuidv4()} {...element} />;
        case "text_image_card":
          return <TextImageCard key={uuidv4()} {...element} />;
        case "icon_nav":
          return <IconNav key={uuidv4()} {...element} />;
        case "person":
          return <Person key={uuidv4()} {...element} />;
        case "icon_bar":
          return <IconBar key={uuidv4()} {...element} />;
        case "appointment_booking":
          return <AppointmentBooking key={uuidv4()} {...element} />;
        case "headline":
          return <Headline key={uuidv4()} {...element} />;
        case "row":
          return <Row key={uuidv4()} {...element} />;
        case "text":
          return <Text key={uuidv4()} {...element} />;
        case "button":
          return <Button key={uuidv4()} {...element} />;
        case "single_image":
          return <Image key={uuidv4()} {...element} />;
        case "single_video":
          return <Video key={uuidv4()} {...element} />;
        case "spacer":
          return <Spacer key={uuidv4()} {...element} />;
        case "lp-hero":
          return <LPHero key={uuidv4()} {...element} />;
        case "lp-ablauf":
          return <LPAblauf key={uuidv4()} {...element} />;
        case "lp-button":
          return <LPButton key={uuidv4()} {...element} />;
        case "lp-faq":
          return <LPFAQ key={uuidv4()} {...element} />;
        case "scroll-section":
          return <ScrollSection key={uuidv4} {...element} />;
        default:
          return <div key={uuidv4()}></div>;
      }
    } else {
      return <div></div>;
    }
  });
};

export default renderElements;
