import React from "react";
import * as styles from "./styles.module.scss";
import Ticker from "./Ticker";

const Counter = (props) => {
  return (
    <div aria-hidden="true" className={styles.wrapper}>
      <Ticker className={`${styles.counter} specialFont`} end={props.counter_value} duration={1.5} suffix="+" />
    </div>
  );
};

export default Counter;
