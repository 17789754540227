import React, { useEffect, useState } from "react";
import * as styles from "./styles.module.scss";

const Image = (props) => {
    const [responsiveStyles, setResponsiveStyles] = useState({});

    const calcStyles = (e) => {
        const windowWidth = document.documentElement.clientWidth;
            
        let styles = {
            marginTop: props.style.marginTop,
            marginBottom: props.style.marginBottom,
            marginLeft: props.style.marginLeft,
            marginRight: props.style.marginRight,
        width: props.style.width,
            display: "block"
        };

        if (windowWidth <= 1100) {
            styles = {
                marginTop: props.style.marginTop,
                marginBottom: props.style.marginBottom,
                marginLeft: props.style.marginLeft,
                marginRight: props.style.marginRight,
            };
        }

        if (windowWidth <= 992) {
            if (props.style.marginTopTablet !== null) {
                props.style.marginTop = props.style.marginTopTablet;
            }
            if (props.style.marginBottomTablet !== null) {
                props.style.marginBottom = props.style.marginBottomTablet;
            }
            if (props.style.marginLeftTablet !== null) {
                props.style.marginLeft = props.style.marginLeftTablet;
            }
            if (props.style.marginRightTablet !== null) {
                props.style.marginRight = props.style.marginRightTablet;
            }
        }

        if (windowWidth <= 768) {
            if (props.style.marginTopMobile !== null) {
                props.style.marginTop = props.style.marginTopMobile;
            }
            if (props.style.marginBottomMobile !== null) {
                props.style.marginBottom = props.style.marginBottomMobile;
            }
            if (props.style.marginLeftMobile !== null) {
                props.style.marginLeft = props.style.marginLeftMobile;
            }
            if (props.style.marginRightMobile !== null) {
                props.style.marginRight = props.style.marginRightMobile;
            }
        }

        if (props.style.position === "center") {
            props.style.marginLeft = "auto";
            props.style.marginRight = "auto";
        } else if (props.style.position === "right") {
            props.style.float = "right";
        }

        setResponsiveStyles(styles);

    }

    useEffect(() => {

        calcStyles();
        window.addEventListener('resize', calcStyles);

        return () => {
            window.addEventListener('resize', calcStyles);
        }
    }, [])

    return (
        <>
            <img
                src={props.url}
                alt={props.alt}
                className={props.imageStyle === "rounded" && styles.rounded}
                style={responsiveStyles}
            />
        </>
    );
};

export default Image;
