import React, { useEffect, useRef } from "react";
import * as styles from "./text.module.scss";
import parse from "html-react-parser";
import elementInViewport from "../../../func/elementInViewPort";
import animateCSS from "../../../func/animateCSS";
import Commas from "../../../images/commas.png";

const Text = (props) => {
	let sProps = {};
	if (props.hasOwnProperty("style")) {
		sProps.marginBottom = props.style.margin_bottom;
	}
	if (props.hasOwnProperty("style")) {
		sProps.marginTop = props.style.margin_top;
	}

	const ref = useRef();

	useEffect(() => {
		let animatedLocal = false;

		const check = () => {
			if (ref.current && props.animate) {
				if (elementInViewport(ref.current)) {
					if (!animatedLocal) {
						animateCSS(ref.current, "fadeInUp").then((message) => {
							ref.current.classList.add(styles.show);
						});
						animatedLocal = true;
					}
				}
			}
		};

		check(ref);

		document.addEventListener(
			"scroll",
			() => {
				check(ref);
			},
			[],
		);
	});

	return (
		<div
			ref={ref}
			style={sProps}
			className={`${styles.wrapper} ${styles[props.classes]} ${!props.animate && styles.show}`}
		>
			{props.quote && <div className={styles.quoteSign}><img src={Commas} alt="Zitat" /></div>}
			{parse(props.text)}
		</div>
	);
};

export default Text;
